body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-indicators li, .carousel-control-prev, .carousel-control-next {
  filter: invert(100%);
}
.carousel-caption {
  color:#333333 !important;
}
.carousel-item img {
  border: 3px solid black;
  height: 400px
  }
.caption {
  padding-left:25px;
}

@media only screen and (max-width: 800px) {
  .carousel-item img {height:230px;}
  .caption {padding-left:0px;}
}
.section {
  padding-top:90px;
  padding-bottom:50px;

}
.section-image {
  padding: 10px;
}
.line{
  border-top: solid 1px grey;
}
nav.navbar {
  position: fixed;
  top: 0;
  z-index: 1030;
  width:100%
}

.section-faded {
  opacity:0
}
.fade-in {
  animation: fadeIn ease 5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
.fade-out {
  animation: fadeOut ease 5s;
}
@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
.fade-in-section {
  opacity: 0;
  transition: opacity 1.2s ease-out;
  will-change: opacity
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;

}
